const getValue = (val?: string | null) => val || '';

const getAddress = (
  streetName?: string | null,
  streetNumber?: string | null,
  postalCode?: string | null,
  town?: string | null,
) => `${getValue(streetName)} ${getValue(streetNumber)}, ${getValue(postalCode)} ${getValue(town)}`;

export const appleMapsUrlBuilder = (
  streetName?: string | null,
  streetNumber?: string | null,
  postalCode?: string | null,
  town?: string | null,
) => {
  const addressFields = getAddress(streetName, streetNumber, postalCode, town);
  return `https://maps.apple.com/?saddr=here&daddr=${encodeURI(addressFields)}`;
};

export const googleMapsUrlBuilder = (
  streetName?: string | null,
  streetNumber?: string | null,
  postalCode?: string | null,
  town?: string | null,
) => {
  const addressFields = getAddress(streetName, streetNumber, postalCode, town);
  return `https://www.google.com/maps/dir/?api=1&destination=${encodeURI(addressFields)}`;
};
