import React, { type MouseEvent, useCallback } from 'react';
import classnames from 'classnames';
import { pseudoIcon } from '@xxxlgroup/hydra-utils/icon/pseudoIcon';
import { BreadcrumbsItem } from '@xxxlgroup/hydra-ui-components';
import { home as homeGlyph } from '@xxxlgroup/hydra-icons';
import { tracking } from 'utils/tracking/tracking';
import useRoutes from 'hooks/useRoutes';
import useMessage from 'components/Message/useMessage';
import Link from 'components/WebshopLink';

import styles from 'components/BreadcrumbsHomeItem/BreadcrumbsHomeItem.scss';

const BreadcrumbsHomeItem = () => {
  const [iconStyleBefore, iconClassNameBefore] = pseudoIcon(homeGlyph);

  const start = useMessage('breadcrumbs.start');

  const handleClick = useCallback((event: MouseEvent<HTMLAnchorElement>) => {
    tracking({ props: {}, type: 'BreadcrumbsHomeItem' }, event);
  }, []);

  const routes = useRoutes();

  return (
    <Link
      className={classnames(styles.home, iconClassNameBefore)}
      as={<BreadcrumbsItem name={start} />}
      data-purpose="breadcrumbs.home"
      href={routes.homePage}
      onClick={handleClick}
      ariaLabel={start}
      style={iconStyleBefore}
    />
  );
};

export default BreadcrumbsHomeItem;
